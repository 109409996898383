import firebase from "firebase/app";

/**
 * Check if a given user can sign-in using a specific Provider.
 * A user can have multiple providers at the same time.
 */
export function isProviderUsed(
  user: firebase.User | null,
  providerId: string
): boolean {
  if (!user) {
    return false;
  }

  return user.providerData.some(
    (provider) => provider?.providerId === providerId
  );
}
