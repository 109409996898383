import mixpanel from "mixpanel-browser";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { AnalyticsContext } from "contexts/AnalyticsContext";
import { styled } from "@mui/system";
import useAuth from "hooks/useAuth";

export const KYAN_COOKIE_CONSENT_KEY = "kyan-cookieConsent" as const;

const AccordionIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  color: theme.palette.text.darkBlue,
}));

const AcceptButton = styled(Button)`
  border-color: ${(props) => props.theme.palette.text.darkBlue};
  color: ${(props) => props.theme.palette.text.darkBlue};
  margin-right: 20px;
  &:hover {
    background-color: #fff;
    border-color: ${(props) => props.theme.palette.text.darkBlue};
    color: ${(props) => props.theme.palette.text.darkBlue};
  }
`;
const CookieBanner = () => {
  const { isAuthenticated } = useAuth();
  const cookieConsentSet =
    localStorage.getItem(KYAN_COOKIE_CONSENT_KEY) === "true";
  const { isMixpanelInitialized } = useContext(AnalyticsContext);
  const [isAnalyticsAllowed, setIsAnalyticsAllowed] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(!cookieConsentSet);

  useEffect(() => {
    if (!isMixpanelInitialized) {
      return;
    }
    setIsAnalyticsAllowed(!mixpanel.has_opted_out_tracking());
  }, [setIsAnalyticsAllowed, isMixpanelInitialized]);

  //Opt in whenever the user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      mixpanel.opt_in_tracking();
    }
  }, [isAuthenticated]);

  const onSave = () => {
    if (isAnalyticsAllowed) {
      mixpanel.opt_in_tracking();
    } else {
      mixpanel.opt_out_tracking();
    }

    localStorage.setItem(KYAN_COOKIE_CONSENT_KEY, "true");
    setShow(false);
  };

  if (!show) {
    return null;
  }

  //Don't render cookie banner if the user is authenticated
  if (isAuthenticated) {
    return null;
  }

  const AnalyticsCookiesSwitch = () => {
    return (
      <Switch
        checked={isAnalyticsAllowed}
        onChange={(event) => setIsAnalyticsAllowed(event.target.checked)}
      />
    );
  };

  return (
    <Box
      style={{
        position: "absolute",
        bottom: 1,
        zIndex: 10000,
      }}
    >
      <Box sx={{ margin: "20px" }}>
        <Accordion elevation={6} disableGutters>
          <AccordionSummary
            expandIcon={<AccordionIcon />}
            sx={{ margin: " 0 20px" }}
          >
            <Box display="flex" width="1" alignItems="center">
              <Typography color={"grey.600"} flexGrow={2}>
                Please be aware that this website uses cookies to enhance the
                user experience.
              </Typography>
              <AcceptButton variant="outlined" onClick={onSave}>
                Accept
              </AcceptButton>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              margin: "20px",
              maxHeight: "400px",
              overflow: "auto",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Typography
                  component="h2"
                  fontWeight="bold"
                  color={"text.darkBlue"}
                >
                  OUR USE OF COOKIES
                </Typography>
                <Typography color={"grey.600"} component="p">
                  We use necessary cookies to make our site work. We'd also like
                  to set analytics cookies that help us make improvements by
                  measuring how you use the site. These will be set only if you
                  accept or create an account.
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography
                  component="h2"
                  fontWeight="bold"
                  color={"text.darkBlue"}
                >
                  NECESSARY COOKIES
                </Typography>
                <Typography color={"grey.600"} component="p">
                  Necessary cookies enable core functionality such as security,
                  network management, and accessibility. You may disable these
                  by changing your browser settings, but this may affect how the
                  website functions.
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography
                  component="h2"
                  fontWeight="bold"
                  color={"text.darkBlue"}
                >
                  ANALYTICS COOKIES
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={<AnalyticsCookiesSwitch />}
                    label={
                      <Typography color={"grey.600"}>
                        Enable analytics cookies
                      </Typography>
                    }
                  />
                </FormGroup>
                <Typography color={"grey.600"} component="p">
                  We'd like to set Analytics cookies to help us improve our
                  website by collecting and reporting information on how you use
                  it. The cookies collect information in a way that does not
                  directly identify anyone. For more information on how these
                  cookies work please see our{" "}
                  <Link
                    target="_blank"
                    color={"text.darkBlue"}
                    href="https://www.kyanhealth.com/privacy-policy/"
                  >
                    Cookie Policy
                  </Link>
                  .
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default CookieBanner;
