import palette from "../theme/palette";

export default function LogoText({
  textColor,
}: {
  textColor?: "light" | "dark";
}) {
  const fillColor =
    textColor === "light" ? palette.light.text.white : palette.dark.brand.blue;
  return (
    <svg
      width="110"
      height="58"
      viewBox="0 0 110 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.1363 33.5679L40.4261 11.822H31.4789L45.5219 43.7001L44.7516 45.4777C43.1517 48.9736 41.0186 50.692 38.4708 50.692C37.2264 50.692 35.8636 50.3365 34.3823 49.6254L33.138 56.1433C34.6193 57.1506 36.6339 57.6838 39.0633 57.6838C45.1663 57.6838 49.077 53.9509 52.1582 46.0702L65.1938 11.822H56.7799L49.1363 33.5679Z"
        fill={fillColor}
      />
      <path
        d="M87.3654 43.9043V26.5432C87.3654 21.5067 90.6243 17.9515 95.1868 17.9515C99.453 17.9515 102.238 21.0326 102.238 26.4247V43.9043H110V24.2916C110 19.9661 108.756 16.7072 106.326 14.3963C103.897 12.0855 100.875 10.9004 97.3791 10.9004C92.6982 10.9004 88.9652 12.9742 87.2469 16.2332V11.7299H79.6625V43.9043H87.3654Z"
        fill={fillColor}
      />
      <path
        d="M65.5859 11.8213H57.0912L68.9684 43.9165H77.463L65.5859 11.8213Z"
        fill={fillColor}
      />
      <path
        d="M24.6332 11.8213H16.1386L28.0157 43.9165H36.5104L24.6332 11.8213Z"
        fill={fillColor}
      />
      <path
        d="M18.9017 20.0275L35.2555 0H25.4788L8.0584 21.5088V0H0V43.7879H8.0584V32.6484L13.5689 25.9528L18.9017 20.0275Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.86 31.8199C58.4798 35.4397 64.3487 35.4397 67.9685 31.8199L72.0324 35.8838C66.1682 41.7481 56.6603 41.7481 50.7961 35.8838L54.86 31.8199Z"
        fill="#F35C5C"
      />
    </svg>
  );
}

LogoText.defaultArgs = {
  textColor: "dark",
};
