import { MIXPANEL_TOKEN } from "config";
import { v4 as uuidv4 } from "uuid";
import { useContext, useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import useAuth from "hooks/useAuth";
import { ReactNode } from "react";
import useDecrypt from "hooks/useDecrypt";
import { userApi } from "redux/userApi";
import { EThreeContext } from "./EThreeContext";
import React from "react";

export const VIDEO_CALL_JOINED_EVENT: Readonly<string> = "joined_video_call";
export const PAGEVIEW_EVENT: Readonly<string> = "pageview";
// We want to use the same constant as in the app so we can use events
// from both applications in one report
export const CHAT_MESSAGE_SENT_EVENT: Readonly<string> = "sentMessage";

const useGenerateMissingAnalyticsId = () => {
  const { user } = useAuth();
  const { eThree, eThreeAvailable } = useContext(EThreeContext);
  const [updateUser, userProgress] = userApi.useUpdateUserMutation();
  const {
    data: userState,
    error,
    isLoading,
  } = userApi.useLoadUserQuery(user?.uid);
  const { analyticsId } = userState || {};
  if (!eThreeAvailable) {
    return;
  }

  async function process() {
    const newAnalyticsId = uuidv4();
    const encryptedAnalyticsId = await eThree.encrypt(newAnalyticsId);
    updateUser({ analyticsId: encryptedAnalyticsId });
  }
  const isUserSignedIn = user?.uid !== undefined;
  const userStateReady =
    // if status != uninitialized then we already send an update, but might not
    // have received the new state yet
    isUserSignedIn &&
    !isLoading &&
    !error &&
    userProgress.status === "uninitialized";
  if (userStateReady && analyticsId === undefined && eThree !== null) {
    process();
  }
};

export const AnalyticsContext = React.createContext({
  isMixpanelInitialized: false,
});

export const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
  useGenerateMissingAnalyticsId();
  const { user } = useAuth();
  const { data: userState } = userApi.useLoadUserQuery(user?.uid);
  const { analyticsId } = userState || {};
  const decryptedAnalyticsId = useDecrypt(analyticsId, null);
  const [isMixpanelInitialized, setIsMixpanelInitialized] = useState(false);

  useEffect(() => {
    if (isMixpanelInitialized || !MIXPANEL_TOKEN) {
      return;
    }

    mixpanel.init(MIXPANEL_TOKEN, {
      api_host: "https://api-eu.mixpanel.com",
    });
    mixpanel.register({ app_id: "kyan-teletherapy-panel" });
    setIsMixpanelInitialized(true);
  }, [isMixpanelInitialized]);

  useEffect(() => {
    if (!decryptedAnalyticsId) {
      return;
    }

    mixpanel.identify(decryptedAnalyticsId);
  }, [decryptedAnalyticsId]);

  return (
    <AnalyticsContext.Provider value={{ isMixpanelInitialized }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
