import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import closeFill from "@iconify/icons-eva/close-fill";
import GoogleLogin from "react-google-login";
import { MIconButton } from "./@material-extend";
import useAuth from "../hooks/useAuth";
import { Button } from "@mui/material";
import { webClientId } from "../config";
import { styled } from "@mui/material/styles";
import googleFill from "@iconify/icons-eva/google-fill";
import palette from "../theme/palette";
import firebase from "firebase/app";

// ----------------------------------------------------------------------

const IconContainer = styled("div")(() => ({
  padding: "8px 16px",
  display: "flex",
}));

export default function GoogleSignInButton({
  onMfa,
}: {
  onMfa: (resolver: firebase.auth.MultiFactorResolver) => void;
}) {
  const { loginWithGoogle, attemptingLogin } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  async function onGoogleSignInSuccess(responseGoogle: any) {
    enqueueSnackbar("Logging in", {
      variant: "success",
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      ),
    });

    try {
      const loginRes = await loginWithGoogle(responseGoogle);

      if (!loginRes?.user?.uid) {
        enqueueSnackbar("Login failed.", {
          variant: "error",
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        });
      }
    } catch (error) {
      const isMFARequired = error.code === "auth/multi-factor-auth-required";
      if (isMFARequired) {
        onMfa(error.resolver);
        return;
      }
    }
  }

  function renderButtonUI(props: { onClick: () => void }) {
    return (
      <Button
        sx={{ flexDirection: "row", my: 2 }}
        variant="contained"
        size={"large"}
        onClick={props.onClick}
        disabled={attemptingLogin}
      >
        Login with google
        <IconContainer>
          <Icon
            icon={googleFill}
            width={25}
            height={25}
            color={palette.light.background.default}
          />
        </IconContainer>
      </Button>
    );
  }

  if (!webClientId) {
    console.error("webclient id not found");
    return null;
  }

  return (
    <GoogleLogin
      clientId={webClientId}
      buttonText="Login"
      render={renderButtonUI}
      onSuccess={onGoogleSignInSuccess}
      onFailure={(responseGoogle) => console.log("failure", responseGoogle)}
      cookiePolicy={"single_host_origin"}
    />
  );
}
