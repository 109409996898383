import { firebaseFunctions } from "contexts/FirebaseContext";

export const getVirgilJwt = firebaseFunctions.httpsCallable("getVirgilJwt");
export const validateAdmin = firebaseFunctions.httpsCallable("validateAdmin");
export const validateGoogleUser =
  firebaseFunctions.httpsCallable("validateGoogleUser");

export const checkCode = firebaseFunctions.httpsCallable("findInvitationCode");
export const incrementUsage = firebaseFunctions.httpsCallable(
  "incrementInvitationCodeUse"
);
export const getAlgoliaSearchKey = firebaseFunctions.httpsCallable(
  "getAlgoliaSearchKey"
);
export const getSurveyResults = firebaseFunctions.httpsCallable(
  "corporateDashboard-getSurveyResults"
);

export const wellbeingInsightsKeyMetrics = firebaseFunctions.httpsCallable(
  "corporateDashboard-wellbeingInsightsKeyMetrics"
);

export const wellbeingInsightsKeyMetricsByCountry =
  firebaseFunctions.httpsCallable(
    "corporateDashboard-wellbeingInsightsKeyMetricsByCountry"
  );

export const wellbeingInsightsKeyWellbeingUserCount =
  firebaseFunctions.httpsCallable(
    "corporateDashboard-wellbeingInsightsKeyWellbeingUserCount"
  );

export const wellbeingInsightsUserCountByCountry =
  firebaseFunctions.httpsCallable(
    "corporateDashboard-wellbeingInsightsUserCountByCountry"
  );

export const getScoreHistoryFromBigQuery = firebaseFunctions.httpsCallable(
  "corporateDashboard-getScoreHistoryFromBigQuery"
);

export const wellbeingInsightsProgressOverview =
  firebaseFunctions.httpsCallable(
    "corporateDashboard-wellbeingInsightsProgressOverview"
  );

export const wellbeingInsightsProgressOverviewByCountry =
  firebaseFunctions.httpsCallable(
    "corporateDashboard-wellbeingInsightsProgressOverviewByCountry"
  );

export const getLiveSessionConfirmedFromBigQuery =
  firebaseFunctions.httpsCallable(
    "corporateDashboard-careExperienceLiveSessionCountByTimezone"
  );

export const topTenSelfCareTopics = firebaseFunctions.httpsCallable(
  "corporateDashboard-getTopTenSelfCareTopics"
);

export const careExperienceTopSelfCareTopics = firebaseFunctions.httpsCallable(
  "corporateDashboard-careExperienceTopSelfCareTopics"
);

export const topThreeCounsellors = firebaseFunctions.httpsCallable(
  "corporateDashboard-topThreeCounsellorDataFromBigQuery"
);

export const getCounsellorStarRating = firebaseFunctions.httpsCallable(
  "corporateDashboard-careExperienceGetCounsellorReviews"
);

export const counsellingChatMessagesExchanged = firebaseFunctions.httpsCallable(
  "corporateDashboard-careExperienceChatMessagesExchangeByTimezone"
);

export const getCounsellingTopicsReports = firebaseFunctions.httpsCallable(
  "corporateDashboard-topTenCounsellingTopicsReports"
);

export const careExperienceTopSearchedCounsellingTopics =
  firebaseFunctions.httpsCallable(
    "corporateDashboard-careExperienceCounsellingTopicsWithFilters"
  );

export const usageInsightAccounts = firebaseFunctions.httpsCallable(
  "corporateDashboard-getUsageInsightCumulativeAccounts"
);

export const topTenDepartments = firebaseFunctions.httpsCallable(
  "corporateDashboard-getTopTenDepartments"
);

export const getUsageInsightAccountByQuestion = firebaseFunctions.httpsCallable(
  "corporateDashboard-usageInsightAccountsCreated"
);

export const getDemographicQuestionAnswers = firebaseFunctions.httpsCallable(
  "corporateDashboard-getDemographicQuestionAnswers"
);

export const topTenAccountCreatedCountriesByQuestion =
  firebaseFunctions.httpsCallable(
    "corporateDashboard-usageInsightsTopTenAccountCreatedCountries"
  );

export const wellbeingInsightsCountryWellbeing =
  firebaseFunctions.httpsCallable(
    "corporateDashboard-wellbeingInsightsCountryWellbeing"
  );

export const getCumulativeMinutesSpent = firebaseFunctions.httpsCallable(
  "corporateDashboard-getCumulativeMinutesSpent"
);

export const sendEmailOnContactUs = firebaseFunctions.httpsCallable(
  "corporateDashboard-sendEmailOnContactUs"
);

export const overAllCorporateDashboardMinutesSpent =
  firebaseFunctions.httpsCallable(
    "corporateDashboard-careExperienceOverallMinutesSpentFix"
  );

export const wellbeingInsightsMoods = firebaseFunctions.httpsCallable(
  "corporateDashboard-wellbeingInsightsMoods"
);

export const wellbeingInsightsMoodsByCountry = firebaseFunctions.httpsCallable(
  "corporateDashboard-wellbeingInsightsMoodsByCountry"
);

export const countCoachesAndTherapists = firebaseFunctions.httpsCallable(
  "corporateDashboard-careExperienceCountCoachesAndTherapists"
);

export const getSessionAndMessageCountByTimezone =
  firebaseFunctions.httpsCallable(
    "corporateDashboard-getSessionAndMessageCountByTimezone"
  );
