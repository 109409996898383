import SpeechBubble from "../../components/SpeechBubble";
import Logo from "../../components/Logo";

import { styled } from "@mui/material/styles";

const Container = styled("div")(() => ({
  position: "relative",
}));

const SpeechBubbleContainer = styled("div")(() => ({
  position: "absolute",
  width: "100%",
  top: "-25%",
  zIndex: 1,
}));

export default function LogoWithSpeechBubble({ text }: { text: string }) {
  return (
    <Container>
      <SpeechBubbleContainer>
        <SpeechBubble text={text} />
      </SpeechBubbleContainer>
      <Logo
        sx={{
          paddingLeft: 8,
          position: "relative",
          top: "7px",
        }}
      />
    </Container>
  );
}
