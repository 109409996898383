import React, { ReactNode, useState } from "react";
import useGetPermissionBasedInvitationCodes from "../hooks/useGetPermissionBasedInvitationCodes";

export const InvitationCodeSelectedFilterContext = React.createContext({
  selected: [] as Array<string>,
  setSelected: (codes: Array<string>) => {},
  permissionBaseInvitationCodes: [] as Array<string>,
  loading: false,
});

export const InvitationCodeSelectedFilterProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { permissionBaseInvitationCodes, loading } =
    useGetPermissionBasedInvitationCodes();
  const byDefaultValue = (permissionBaseInvitationCodes ?? []) as Array<string>;
  const [selected, setSelected] = useState<Array<string>>(byDefaultValue);

  return (
    <InvitationCodeSelectedFilterContext.Provider
      value={{
        selected,
        setSelected,
        permissionBaseInvitationCodes,
        loading,
      }}
    >
      {children}
    </InvitationCodeSelectedFilterContext.Provider>
  );
};
