import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";

import { LoadingButton } from "@mui/lab";
import { Grid, Stack, TextField, Typography } from "@mui/material";

import { validatePhoneNumber } from "utils/validatePhoneNumber";

export type PhoneFormValues = {
  phoneNumber: string;
};

const PhoneNumberSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required("Please enter your phone number")
    .test(
      "is-phone",
      "This does not look like a valid phone number. Make sure to include your country code (e.g. +1)",
      (value) => validatePhoneNumber(value)
    ),
});

type Props = {
  onSubmit: (values: PhoneFormValues) => Promise<void>;
};

export function PhoneNumberForm({ onSubmit }: Props) {
  const phoneNumberForm = useFormik<PhoneFormValues>({
    initialValues: {
      phoneNumber: "",
    },
    validationSchema: PhoneNumberSchema,
    onSubmit,
  });
  const { isSubmitting, handleSubmit, getFieldProps, errors } = phoneNumberForm;

  return (
    <FormikProvider value={phoneNumberForm}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid item lg={12}>
            <Typography textAlign="center">
              Please provide a phone number for two-factor authentication
            </Typography>
            <Stack paddingTop="32px" spacing={3}>
              <TextField
                fullWidth
                {...getFieldProps("phoneNumber")}
                placeholder="+1 123 456"
                type="text"
                label={"Phone number"}
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber}
              />

              <div id="recaptcha-container-id"></div>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Send verification code
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
