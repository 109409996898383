import { useState } from "react";

import { Typography } from "@mui/material";

import { ButtonLink } from "components/ButtonLink";
import { EnrollMFADialog } from "components/EnrollMFADialog";
import { PaperPage } from "components/PaperPage";
import { PATHS } from "routes/paths";
import { MFAStep } from "../../@types/authentication";

export function EnrollMFAPage() {
  const [step, setStep] = useState<MFAStep>(MFAStep.ENROLL);

  if (step !== MFAStep.ENROLL) {
    let title = "Successfully enrolled";
    let message = "The factor was added to your account";
    if (step === MFAStep.ERROR) {
      title = "An error occurred";
      message =
        "An error occurred. Try again or contact support if the issue persists";
    }

    return (
      <PaperPage
        headTitle={"Enroll in two-factor authentication"}
        title={title}
      >
        <Typography textAlign="center">{message}</Typography>
        {/* <a> tag forces the browser to navigate which causes a page reload and reloads
            the authentication handler.

            onAuthStateChanged handler loads therapist profile and other metadata
            but fires to early in this registration flow(when we call signInWithEmailLink).

            Page reload fixes that problem.
          */}
        <ButtonLink to={PATHS.root} title={"Back"} aTag />
      </PaperPage>
    );
  }

  return (
    <PaperPage
      headTitle={"Enroll in two-factor authentication"}
      title={"Enroll in two-factor authentication"}
    >
      <EnrollMFADialog
        onSuccess={() => {
          setStep(MFAStep.SUCCESS);
        }}
        onError={() => {
          setStep(MFAStep.ERROR);
        }}
      />
    </PaperPage>
  );
}
