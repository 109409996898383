const FILL = "*".repeat(6);

/**
 * A primitive function that takes a string representing a phone number.
 * Tries to display the country code (e.g. +123) and the last 3 digits of
 * the number while rendering the rest of the number as '*'.
 */
export function obfuscatePhoneNumber(phoneNumber: string) {
  // We want to show the first 3 (country code) and the last 3 digits
  // of the phone number but we don't want to accidentally
  // leak 6 digit numbers.
  if (phoneNumber.trim().length < 11) {
    return FILL;
  }

  const start = phoneNumber.substr(0, 4);
  const end = phoneNumber.substr(-3, 3);
  return start + FILL + end;
}
