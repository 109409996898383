import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { Box, IconButton } from "@mui/material";
// hooks
// components
import { MHidden } from "../../components/@material-extend";

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({
  onOpenSidebar,
}: DashboardNavbarProps) {
  return (
    <Box position="absolute" right="0" top="34px">
      <MHidden width="lgUp">
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "text.primary" }}
        >
          <Icon icon={menu2Fill} />
        </IconButton>
      </MHidden>
    </Box>
  );
}
