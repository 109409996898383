// This is only a helper for type declarations. Do not export
// and use this, as it's not properly implemented.
type GoogleApiError = {
  code: string;
  message: string;
};

/**
 * Ensure a given object offers the same properties as an Error thrown by the
 * firebase-sdk in response to an API Exception.
 */
export function isGoogleApiError(obj: unknown): obj is GoogleApiError {
  if (!obj || typeof obj !== "object" || obj === null) {
    return false;
  }

  if (!obj.hasOwnProperty("code") && !obj.hasOwnProperty("message")) {
    return false;
  }

  const potentialError = obj as {
    code: unknown;
    message: unknown;
  };
  return (
    typeof potentialError.code === "string" &&
    typeof potentialError.message === "string"
  );
}
