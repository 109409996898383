import { ROLES } from "./constants";
import numeral from "numeral";

export function getAnYearAheadDate(): Date {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  return new Date(year + 1, month, day);
}

export function getStringFromFieldObject(field: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...rest } = field;
  const keys = Object.keys(rest);
  let joinedString = "";
  for (let key of keys) {
    joinedString += field[key];
  }
  return joinedString;
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: any) {
  return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param sources
 */
export function mergeDeep<Type>(target: any, ...sources: [Type]): Type {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

/**
 *
 * @param {Array<string>} arr, an array of string
 * @return {Array<string>}
 */
export function removeDuplicateStrings(arr: Array<string>): Array<string> {
  if (!Array.isArray(arr)) return [];

  // @ts-ignore
  return [...new Set(arr)];
}

/**
 * a function to check if an array of roles contains valid roles
 * @param {Array<string>} roles, an array of strings containing roles
 * @return {boolean}
 */
export function isValidRoles(roles: Array<string>): boolean {
  if (!Array.isArray(roles)) return false;
  if (!roles.length) return true; // considering empty array as valid roles array
  // @ts-ignore
  const ALL_ROLES = Object.keys(ROLES).map((key) => ROLES[key]);
  return roles.every((role: string) => ALL_ROLES.includes(role));
}

/**
 * a function to check if an array of strings is a proper subset contains valid supArr
 * @param {Array<string>} supArr, an array of strings being checked for superArr
 * @param {Array<string>} subArr, an array of strings being checked for subset Arr
 * @return {boolean}
 */
export function isProperSubset(
  supArr: Array<string>,
  subArr: Array<string>
): boolean {
  if (!Array.isArray(supArr) || !Array.isArray(subArr)) return false;
  if (!supArr.length && !subArr.length) return true; // considering both empty array as valid subset array
  return subArr.every((item: string) => supArr.includes(item));
}

/**
 * a function to check if an array of strings has atleast one allowed permission
 * @param {Array<string>} allowedPerms, an array of strings of allowedPerms
 * @param {Array<string>} requiredPerms, an array of strings of requiredPerms
 * @return {boolean}
 */
export function hasValidPermissions(
  allowedPerms: Array<string>,
  requiredPerms: Array<string>
): boolean {
  if (!Array.isArray(allowedPerms) || !Array.isArray(requiredPerms))
    return false;
  if (!allowedPerms.length && !requiredPerms.length) return true;
  for (let requiredPerm of requiredPerms) {
    if (allowedPerms.includes(requiredPerm)) {
      return true;
    }
  }
  return false;
}

export const formatNumber = (number: number, format: string = "00") => {
  return numeral(number).format(format);
};
