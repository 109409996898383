import { useRef, useState } from "react";
import firebase from "firebase/app";

import { Box, Stack, Typography } from "@mui/material";

// components
import { LoginForm } from "components/authentication/login";
import AuthLayout from "layouts/AuthLayout";
import GoogleSignInButton from "components/GoogleSignInButton";
import StyledLink from "components/StyledLink";
import { MFALogin } from "../../components/authentication/login/MFALogin";

enum Mode {
  MFA = "mfa",
  LOG_IN = "login",
}

const Login = () => {
  const [mode, setMode] = useState<Mode>(Mode.LOG_IN);
  const mfaResolver = useRef<firebase.auth.MultiFactorResolver | undefined>();

  if (mode === Mode.MFA && mfaResolver.current) {
    return <MFALogin resolver={mfaResolver.current} />;
  }

  return (
    <AuthLayout headTitle={"Sign In"} title={"Hi, Welcome back!"}>
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom aria-label="Login title">
            Sign in to Kyan
          </Typography>
        </Box>
      </Stack>

      <LoginForm
        onMfa={(resolver: firebase.auth.MultiFactorResolver) => {
          mfaResolver.current = resolver;
          setMode(Mode.MFA);
        }}
      />

      <Typography textAlign={"center"} sx={{ color: "text.secondary", mt: 2 }}>
        OR
      </Typography>
      <GoogleSignInButton
        onMfa={(resolver: firebase.auth.MultiFactorResolver) => {
          mfaResolver.current = resolver;
          setMode(Mode.MFA);
        }}
      />

      <Typography
        variant={"body1"}
        mt={2}
        color={"grey.600"}
        textAlign={"center"}
      >
        Don't have an account yet?
        <Box mx={1} display="inline-block" component="span">
          <StyledLink to="/">Sign Up</StyledLink>
        </Box>
      </Typography>
    </AuthLayout>
  );
};

export default Login;
