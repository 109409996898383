import { db, firebaseAuth } from "contexts/FirebaseContext";
import { collections } from "utils/constants";

import { createApi } from "@reduxjs/toolkit/query/react";
import { UserId, UserProfile } from "../@types/user";

type ImmutableUserState = Pick<UserProfile, "invitationCode">;
type MutableUserState = Partial<Pick<UserProfile, "fullname" | "analyticsId">>;

type UserState = MutableUserState & ImmutableUserState;

const customBaseQuery = (
  args: any,
  { signal, dispatch, getState }: any,
  extraOptions: any
) => {
  return { data: {} as UserState };
};

export const userApi = createApi({
  reducerPath: "userRTK",
  baseQuery: customBaseQuery,
  tagTypes: ["User"],
  endpoints: (build) => ({
    loadUser: build.query<UserState, UserId>({
      providesTags: ["User"],
      async queryFn(currentUser, _api, _extraOptions, _stuff) {
        if (!currentUser) {
          return {
            error: "No user logged in",
          };
        }
        const ref = db.collection(collections.USERS).doc(currentUser);
        const doc = await ref.get();
        const result = doc.data();
        return { data: result as UserState };
      },
    }),

    updateUser: build.mutation<UserState, MutableUserState>({
      invalidatesTags: ["User"],
      async queryFn(user, _api, _extraOptions, _stuff) {
        const currentUser = firebaseAuth().currentUser?.uid;
        if (!currentUser) {
          throw new Error(
            "User is not signed in, but we try to update user data"
          );
        }

        await db
          .collection(collections.USERS)
          .doc(currentUser)
          .set(user, { merge: true });
        return { data: user as UserState };
      },
    }),
  }),
});
