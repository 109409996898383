import React, { useState } from "react";
import * as Yup from "yup";
import { Form, FormikHelpers, FormikProvider, useFormik } from "formik";

import { Grid, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

type Props = {
  email: string;
  showPasswordAuthentication: boolean;
  showGoogleAuthentication: boolean;
  onGoogleSignIn: () => void;
  onSubmit: (
    values: PasswordFormValues,
    helpers: FormikHelpers<PasswordFormValues>
  ) => Promise<void>;
};

export type PasswordFormValues = {
  password: string;
};

const PasswordFormSchema = Yup.object({
  password: Yup.string().required("Please provide your password"),
});

export function ReauthenticateForm(props: Props) {
  const [googleSignInPending, setGoogleSignInPending] =
    useState<boolean>(false);

  const {
    email,
    onSubmit,
    onGoogleSignIn,
    showPasswordAuthentication,
    showGoogleAuthentication,
  } = props;

  const formik = useFormik<PasswordFormValues>({
    initialValues: {
      password: "",
    },
    validationSchema: PasswordFormSchema,
    onSubmit,
  });

  function googleSignInHandler() {
    setGoogleSignInPending(true);
    onGoogleSignIn();
  }

  if (googleSignInPending) {
    return (
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        <Grid item lg={12}>
          <Typography textAlign="center">
            Waiting to reauthenticate...
          </Typography>
        </Grid>
      </Grid>
    );
  }

  const { getFieldProps, errors, handleSubmit, isSubmitting } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid item lg={12}>
            <Typography textAlign="center">
              {`Please authenticate the account ${email}`}
            </Typography>
            <Stack paddingTop="32px" spacing={3}>
              {showPasswordAuthentication && (
                <React.Fragment>
                  <TextField
                    fullWidth
                    {...getFieldProps("password")}
                    type="password"
                    label={"Password"}
                    error={Boolean(errors?.password)}
                    helperText={errors.password}
                  />

                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Continue
                  </LoadingButton>
                </React.Fragment>
              )}
              {showGoogleAuthentication && (
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  onClick={googleSignInHandler}
                >
                  Login with Google
                </LoadingButton>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
