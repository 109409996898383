import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { getUsersTimezoneByInvitationCodes } from "../services/UserService";
import * as CloudFunctions from "../utils/firebase/cloudFunctions";
import { QueryContext } from "./QueryContext";
import { extractValuesFromCommaSeparatedString } from "../utils/extractValuesFromCommaSeparatedString";
import { filterTimezonesByCountry } from "../utils/filterTimezonesByCountry";

export type Country = { name: string; count: number };

export const CountryFilterContext = React.createContext({
  timezoneWithCountryNameForSessions: {} as Record<string, Country>,
  timezoneWithCountryNameForMessages: {} as Record<string, Country>,
  countryNamesListForMessages: [] as Array<Country>,
  countryNamesListForSessions: [] as Array<Country>,
  sessionSelectedCountry: "",
  messageSelectedCountry: "",
  selectedCountryFromDateFilter: "",
  selectedCountryTimezones: [] as Array<string>,
  setMessageSelectedCountry: (messageSelectedCountry: string) => {},
  setSessionSelectedCountry: (sessionSelectedCountry: string) => {},
  setSelectedCountryTimezones: (sessionSelectedCountry: Array<string>) => {},
  handleDropdownChange: (
    selectedCountry: string,
    timezonesWithCountryName: Record<string, Country>
  ) => {},
  setSelectedCountryFromDateFilter: (
    selectedCountryFromDateFilter: string
  ) => {},
});

export const CountryFilterProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [
    timezoneWithCountryNameForSessions,
    setTimezoneWithCountryNameForSessionss,
  ] = useState<Record<string, { name: string; count: number }>>({});
  const [
    timezoneWithCountryNameForMessages,
    setTimezoneWithCountryNameForMessages,
  ] = useState<Record<string, { name: string; count: number }>>({});
  const [countryNamesListForSessions, setCountryNamesListForSessions] =
    useState<Array<{ name: string; count: number }>>([]);
  const [countryNamesListForMessages, setCountryNamesListForMessages] =
    useState<Array<{ name: string; count: number }>>([]);
  const [messageSelectedCountry, setMessageSelectedCountry] =
    useState<string>("");
  const [sessionSelectedCountry, setSessionSelectedCountry] =
    useState<string>("");
  const [selectedCountryTimezones, setSelectedCountryTimezones] = useState<
    Array<string>
  >([]);
  const [selectedCountryFromDateFilter, setSelectedCountryFromDateFilter] =
    useState<string>("");

  const { queryInvitationCode, startDate, endDate } = useContext(QueryContext);

  useEffect(() => {
    async function fetchAndSetTimezoneWithCountryName() {
      if (queryInvitationCode?.trim()) {
        setCountryNamesListForSessions([]);
        setCountryNamesListForMessages([]);
        const invitationCodes =
          extractValuesFromCommaSeparatedString(queryInvitationCode);
        const timezones = await getUsersTimezoneByInvitationCodes(
          invitationCodes
        );

        const timezoneWithCountryNames =
          await CloudFunctions.getSessionAndMessageCountByTimezone({
            timezones,
            invitationCode: queryInvitationCode,
            startDate,
            endDate,
          });

        const {
          sessionsTimezoneCountryMapping,
          messagesTimezoneCountryMapping,
          messagesCountryList,
          sessionCountryList,
        } = timezoneWithCountryNames?.data ?? {};

        if (sessionsTimezoneCountryMapping || messagesTimezoneCountryMapping) {
          setCountryNamesListForSessions(sessionCountryList);
          setCountryNamesListForMessages(messagesCountryList);
          setTimezoneWithCountryNameForSessionss(
            sessionsTimezoneCountryMapping ?? {}
          );
          setTimezoneWithCountryNameForMessages(
            messagesTimezoneCountryMapping ?? {}
          );
        }
      }
    }
    fetchAndSetTimezoneWithCountryName();
  }, [endDate, queryInvitationCode, startDate]);

  const handleDropdownChange = useCallback(
    (
      selectedCountry: string,
      timezonesWithCountryName: Record<string, Country>
    ) => {
      setSelectedCountryFromDateFilter("");

      const matchingTimezones = filterTimezonesByCountry(
        selectedCountry,
        timezonesWithCountryName
      );
      setSelectedCountryTimezones(matchingTimezones);
    },
    []
  );

  return (
    <CountryFilterContext.Provider
      value={{
        timezoneWithCountryNameForSessions,
        timezoneWithCountryNameForMessages,
        countryNamesListForSessions,
        countryNamesListForMessages,
        sessionSelectedCountry,
        messageSelectedCountry,
        setMessageSelectedCountry,
        setSessionSelectedCountry,
        selectedCountryTimezones,
        setSelectedCountryTimezones,
        setSelectedCountryFromDateFilter,
        selectedCountryFromDateFilter,
        handleDropdownChange,
      }}
    >
      {children}
    </CountryFilterContext.Provider>
  );
};
