import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { forwardRef, useEffect, useCallback, ReactNode } from "react";
// material
import { Box, BoxProps } from "@mui/material";
// utils
import track from "../utils/analytics";

// ----------------------------------------------------------------------

interface PageProps extends BoxProps {
  children: ReactNode;
  headTitle?: string;
}

const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, headTitle = "", ...other }, ref) => {
    const { pathname } = useLocation();

    const sendPageViewEvent = useCallback(() => {
      track.pageview({
        page_path: pathname,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      sendPageViewEvent();
    }, [sendPageViewEvent]);

    return (
      <Box ref={ref} {...other}>
        <Helmet>
          <title>{`${headTitle} | Kyan Health`}</title>
        </Helmet>
        {children}
      </Box>
    );
  }
);

export default Page;
