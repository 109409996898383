// i18n
import "./locales/i18n";

// scroll bar
import "simplebar/src/simplebar.css";

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

// material
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// redux
import { store, persistor } from "./redux/store";
// contexts
import { SettingsProvider } from "./contexts/SettingsContext";
// components
import LoadingScreen from "./components/LoadingScreen";

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version
import { AuthProvider } from "./contexts/AuthProvider";

//Sentry
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

//
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import removeConsoleLogs from "utils/remove-console-logs";
import EThreeProvider from "contexts/EThreeContext";
import QueryProvider from "contexts/QueryContext";
import { InvitationCodeSelectedFilterProvider } from "./contexts/InvitationCodeSelectedFilterContext";
import { DemographicQuestionFilterProvider } from "./contexts/DemographicQuestionFilterContext";

removeConsoleLogs();

const NODE_ENV = process.env.NODE_ENV;

//Don't use Sentry in dev or it reports too many issues. Can be filtered out, but still unnecessary
if (NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://710f037be526bb75514e1dcafe2f0988@o4507130541309952.ingest.de.sentry.io/4507135983091792",
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsoleIntegration({
        levels: ["error"],
      }),
    ],
    environment: NODE_ENV,

    //Set sample rate to 20% in production. This represesnts the percentage of page loads that sentry should monitor for performance.
    //Sentry recommends not monitoring every page load in prod - https://docs.sentry.io/platforms/javascript/performance/#verify
    tracesSampleRate: 0.2,
  });
}

// ----------------------------------------------------------------------

ReactDOM.render(
  <HelmetProvider>
    <ReduxProvider store={store}>
      <EThreeProvider>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <BrowserRouter>
                <AuthProvider>
                  <QueryProvider>
                    <InvitationCodeSelectedFilterProvider>
                      <DemographicQuestionFilterProvider>
                        <App />
                      </DemographicQuestionFilterProvider>
                    </InvitationCodeSelectedFilterProvider>
                  </QueryProvider>
                </AuthProvider>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
        </PersistGate>
      </EThreeProvider>
    </ReduxProvider>
  </HelmetProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
