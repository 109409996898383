import { createContext, ReactNode, useState, Dispatch, useEffect } from "react";
import { addDays, subYears } from "date-fns";
import { DepartmentQuestionParam } from "../components/DropDownList";
import { CountriesDropdownOptions } from "../utils/constants";

type QueryContextProvider = {
  invitationCode: string;
  queryInvitationCode: string;
  date: Date;
  rangeEndDate: Date;
  setInvitationCode: Dispatch<string>;
  setSelectedQuestionParams: Dispatch<DepartmentQuestionParam>;
  setFirstFiveEnglishQuestionsByDepartment: Dispatch<
    Array<DepartmentQuestionParam>
  >;
  firstFiveEnglishQuestionsByDepartment: Array<DepartmentQuestionParam>;
  selectedQuestionParams: DepartmentQuestionParam;
  setDate: Dispatch<Date>;
  setRangeEndDate: Dispatch<Date>;
  queryDate: string;
  startDate: string;
  endDate: string;
  rangeStartDate: string;
  isEnabledDemographicQuestionFilters: boolean;
  setIsEnabledDemographicQuestionFilters: (
    isEnabledDemographicQuestionFilters: boolean
  ) => void;
  shouldFetch: boolean;
  moodDate: Date;
  setMoodDate: Dispatch<Date>;
  moodRangeStartDate: string;
  setMoodRangeStartDate: Dispatch<string>;
  moodRangeEndDate: Date;
  setMoodRangeEndDate: Dispatch<Date>;
  moodEndDate: string;
  selectedCountry: string;
  setSelectedCountry: Dispatch<string>;
};

const defaultRangeEndDate = addDays(new Date(), 1);
const defaultDate = subYears(new Date(), 3);
const defaultInvitationCode = "";

const defaultValue = {
  invitationCode: defaultInvitationCode,
  queryInvitationCode: defaultInvitationCode,
  date: defaultDate,
  rangeEndDate: defaultRangeEndDate,
  setInvitationCode: () => {},
  setSelectedQuestionParams: () => {},
  setFirstFiveEnglishQuestionsByDepartment: () => {},
  setDate: () => {},
  setEndDate: () => {},
  setRangeEndDate: () => {},
  queryDate: "",
  startDate: "",
  endDate: "",
  rangeStartDate: "",
  isEnabledDemographicQuestionFilters: false,
  setIsEnabledDemographicQuestionFilters: () => {},
  blankInvitationCode: true,
  selectedCountry: "",
  setSelectedCountry: () => {},
};

// @ts-ignore
export const QueryContext = createContext<QueryContextProvider>(defaultValue);

const delay = 500;

const QueryProvider = ({ children }: { children: ReactNode }) => {
  const [invitationCode, setInvitationCode] = useState<string>(
    defaultInvitationCode
  );

  const [
    isEnabledDemographicQuestionFilters,
    setIsEnabledDemographicQuestionFilters,
  ] = useState<boolean>(false);

  const [selectedQuestionParams, setSelectedQuestionParams] =
    useState<DepartmentQuestionParam>();
  const [
    firstFiveEnglishQuestionsByDepartment,
    setFirstFiveEnglishQuestionsByDepartment,
  ] = useState<Array<DepartmentQuestionParam>>([]);
  const [date, setDate] = useState<Date>(defaultDate);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>(
    defaultRangeEndDate?.toISOString()?.split("T")[0]
  );
  const [rangeStartDate, setRangeStartDate] = useState<string>(
    defaultDate?.toISOString()?.split("T")[0]
  );
  const [rangeEndDate, setRangeEndDate] = useState<Date>(defaultRangeEndDate);

  //Mood date range filter needs to work independently of the other date range filter,
  //so we're duplicating the state here
  const [moodDate, setMoodDate] = useState<Date>(defaultDate);
  const [moodRangeStartDate, setMoodRangeStartDate] = useState<string>(
    defaultDate?.toISOString()?.split("T")[0]
  );
  const [moodRangeEndDate, setMoodRangeEndDate] =
    useState<Date>(defaultRangeEndDate);
  const [moodEndDate, setMoodEndDate] = useState<string>(
    defaultRangeEndDate?.toISOString()?.split("T")[0]
  );

  //State that will be passed to charts for querying. Wrapped in timeouts below so we
  //don't query as the user types
  const [queryInvitationCode, setQueryInvitationCode] = useState<string>(
    defaultInvitationCode
  );
  const [queryDate, setQueryDate] = useState<string>(
    defaultDate?.toISOString()?.split("T")[0]
  );

  const [selectedCountry, setSelectedCountry] = useState<string>(
    CountriesDropdownOptions.ALL_COUNTRIES
  );

  useEffect(() => {
    if (!date) {
      return;
    }

    const dateTimeout = setTimeout(() => {
      setRangeStartDate(date?.toISOString()?.split("T")[0]);
      setStartDate(date?.toISOString()?.split("T")[0]);
      setEndDate(rangeEndDate?.toISOString()?.split("T")[0]);
      setQueryDate(date?.toISOString()?.split("T")[0]);
    });

    return () => {
      clearTimeout(dateTimeout);
    };
  }, [date, rangeEndDate]);

  useEffect(() => {
    if (!moodDate) {
      return;
    }

    const dateTimeout = setTimeout(() => {
      setMoodRangeStartDate(moodDate?.toISOString()?.split("T")[0]);
      setMoodEndDate(moodRangeEndDate?.toISOString()?.split("T")[0]);
    });

    return () => {
      clearTimeout(dateTimeout);
    };
  }, [moodDate, moodRangeEndDate]);

  useEffect(() => {
    const invitationCodeTimeout = setTimeout(() => {
      setQueryInvitationCode(invitationCode);
    }, delay);

    return () => {
      clearTimeout(invitationCodeTimeout);
    };
  }, [invitationCode]);

  const shouldFetch = Boolean(queryInvitationCode?.trim());

  return (
    <QueryContext.Provider
      value={{
        invitationCode,
        setInvitationCode,
        queryInvitationCode,
        setSelectedQuestionParams,
        // @ts-ignore
        selectedQuestionParams,
        setFirstFiveEnglishQuestionsByDepartment,
        firstFiveEnglishQuestionsByDepartment,
        setDate,
        date,
        queryDate,
        startDate,
        endDate,
        setRangeEndDate,
        rangeEndDate,
        rangeStartDate,
        isEnabledDemographicQuestionFilters,
        setIsEnabledDemographicQuestionFilters,
        shouldFetch,
        moodDate,
        setMoodDate,
        moodRangeStartDate,
        setMoodRangeStartDate,
        moodRangeEndDate,
        setMoodRangeEndDate,
        moodEndDate,
        setSelectedCountry,
        selectedCountry,
      }}
    >
      {children}
    </QueryContext.Provider>
  );
};

export default QueryProvider;
