// hooks
import useAuth from "../hooks/useAuth";
//
import { MAvatar } from "./@material-extend";
import { MAvatarProps } from "./@material-extend/MAvatar";
import createAvatar from "../utils/createAvatar";
import { userApi } from "redux/userApi";
// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();
  const { data: userState } = userApi.useLoadUserQuery(user?.uid);

  const userName = userState?.fullname || user?.displayName;
  const avatar = createAvatar(userName);

  return (
    <MAvatar
      src={user?.photoURL}
      alt={userName}
      color={user?.photoURL ? "default" : avatar.color}
      {...other}
    >
      {avatar.name}
    </MAvatar>
  );
}
