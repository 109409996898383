import mixpanel from "mixpanel-browser";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { PAGEVIEW_EVENT } from "contexts/AnalyticsContext";

export const usePageViewTracking = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const source = searchParams.get("source");

  useEffect(() => {
    mixpanel.track(PAGEVIEW_EVENT, {
      pathname: location.pathname,
      code,
      source,
    });
  }, [location.pathname, code, source]);
};
