import React, { ReactNode, useState } from "react";

import { DepartmentQuestionParam } from "../components/DropDownList";
import { DataItem } from "../utils/getDemographicQuestionAnswers";

export const DemographicQuestionFilterContext = React.createContext({
  selectedQuestion: {} as DepartmentQuestionParam | undefined,
  questionAnswer: [] as Array<string>,
  selectedAnswers: [] as Array<string>,
  questionAnswerWithId: [] as Array<DataItem>,
  setSelectedQuestion: (
    selectedQuestion: DepartmentQuestionParam | undefined
  ) => {},
  setQuestionAnswer: (questionAnswer: Array<string>) => {},
  setSelectedAnswers: (selectedAnswers: Array<string>) => {},
  setQuestionAnswersWithId: (questionAnswerWithId: Array<DataItem>) => {},
});

export const DemographicQuestionFilterProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [selectedQuestion, setSelectedQuestion] =
    useState<DepartmentQuestionParam>();

  const [questionAnswer, setQuestionAnswer] = useState<Array<string>>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<Array<string>>([]);
  const [questionAnswerWithId, setQuestionAnswersWithId] = useState<
    Array<DataItem>
  >([]);

  return (
    <DemographicQuestionFilterContext.Provider
      value={{
        selectedQuestion,
        questionAnswer,
        selectedAnswers,
        setSelectedQuestion,
        setQuestionAnswer,
        setSelectedAnswers,
        questionAnswerWithId,
        setQuestionAnswersWithId,
      }}
    >
      {children}
    </DemographicQuestionFilterContext.Provider>
  );
};
