export const PATHS = {
  login: "/login",
  register: "/sign-up",
  root: "/",
  resetPassword: "/reset-password",
  usageInsights: "/usage_insights",
  resources: "/resources",
  wellbeingInsights: "/wellbeing-insights",
  viewDetail: "/view-detail",
  careExperience: "/care-experience",
  generatePdf: "/generatePdf",
  trainingPrograms: "/training-programs",
  accountMFA: "/account/mfa",
} as const;
