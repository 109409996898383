import { useState, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Alert, AlertTitle, Container, Box } from "@mui/material";
import Login from "pages/authentication/Login";
// hooks
import useAuth from "../hooks/useAuth";
import { hasValidPermissions } from "utils/helpers";
import { CORPORATE_DASH_ADMINS } from "../utils/constants";
import { useCurrentRole } from "./RoleBasedGuard";
import AccountPopover from "../layouts/dashboard/AccountPopover";
// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );
  const usrRoles = useCurrentRole();

  const isSuperAdminOrCorporateDashAdmin = hasValidPermissions(
    usrRoles,
    CORPORATE_DASH_ADMINS
  );

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (!isSuperAdminOrCorporateDashAdmin) {
    return (
      <Box mt={2}>
        <Container>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <AccountPopover></AccountPopover>
          </Box>

          <Alert severity="error">
            <AlertTitle>Permission Denied</AlertTitle>
            You do not have permission to access this page
          </Alert>
        </Container>
      </Box>
    );
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
