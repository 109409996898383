import { Box, Link } from "@mui/material";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import StyledLink from "components/StyledLink";
import useAuth from "hooks/useAuth";
import { styled } from "@mui/material/styles";

const year = new Date().getFullYear();

const StyledLogoutLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
  display: "inline-block",
  margin: "0 8px",
}));

const LoginLink = (footerTextColor: string) => (
  <Box>
    <Typography variant={"body1"} color={footerTextColor} textAlign={"center"}>
      Already have an account?
      <Box component="span" mx={1} display="inline-block">
        <StyledLink to="/login">Login</StyledLink>
      </Box>
    </Typography>
  </Box>
);

const LogoutLink = (footerTextColor: string, clickHandler?: () => {}) => (
  <Box>
    <Typography variant={"body1"} textAlign={"center"}>
      <Box component="span" color={footerTextColor}>
        See you next time!
      </Box>
      <StyledLogoutLink onClick={clickHandler}>Logout</StyledLogoutLink>
    </Typography>
  </Box>
);
export default function TermsFooter({ showLink }: { showLink?: boolean }) {
  const theme = useTheme();
  const footerTextColor = theme.palette.grey["500"];

  const { isAuthenticated, logout } = useAuth();

  const linkToRender = isAuthenticated
    ? LogoutLink(footerTextColor, logout)
    : LoginLink(footerTextColor);

  return (
    <Box
      flexDirection={{ xs: "column-reverse", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      mb={{ xs: 2, lg: 1 }}
      display={{ xs: "none", lg: "flex" }}
    >
      <Typography
        variant="body1"
        color={footerTextColor}
        mt={{ xs: 1, lg: 0 }}
        width="250px"
      >
        ©{year} Kyan Health
      </Typography>

      {showLink && linkToRender}

      <Box display="flex" flexDirection="row">
        <Link
          variant="body1"
          color={footerTextColor}
          target="_blank"
          href="https://www.kyanhealth.com/terms/"
        >
          Terms of service
        </Link>
        <Box mx={1}>
          <Typography variant="body1" color={footerTextColor}>
            |
          </Typography>
        </Box>
        <Link
          target="_blank"
          color={footerTextColor}
          variant="body1"
          href="https://storage.googleapis.com/media.landbot.io/193750/channels/270W4YX9NZPAQN7AZN70QKD7ZVHESQOL.pdf"
        >
          Privacy Policy
        </Link>
      </Box>
    </Box>
  );
}

TermsFooter.defaultProps = {
  showLink: true,
};
