import { useState } from "react";
import firebase from "firebase/app";
import { useNavigate } from "react-router";

import { LoadingButton } from "@mui/lab";
import { Grid, Stack, Typography } from "@mui/material";

import useAuth from "hooks/useAuth";
import { ButtonLink } from "./ButtonLink";
import { PATHS } from "routes/paths";
import { MFAStep } from "../@types/authentication";

export function Message(props: { step: MFAStep }) {
  const { step } = props;

  return (
    <Grid container spacing={2} sx={{ justifyContent: "center" }}>
      <Grid item lg={12}>
        <Typography textAlign="center">{step}</Typography>
        <Stack paddingTop="32px" spacing={3}>
          <ButtonLink to={PATHS.root} title={"Back"} />
        </Stack>
      </Grid>
    </Grid>
  );
}

export function EmailVerificationDialog() {
  const [step, setStep] = useState<MFAStep>(MFAStep.REQUEST);
  const [sending, setSending] = useState<boolean>(false);

  const navigate = useNavigate();
  const { user } = useAuth();

  if (!user) {
    navigate(PATHS.login);
    return null;
  }

  if (step === MFAStep.SUCCESS || step === MFAStep.ERROR) {
    return <Message step={step} />;
  }

  return (
    <Grid container spacing={2} sx={{ justifyContent: "center" }}>
      <Grid item lg={12}>
        <Typography textAlign="center">
          {`You need to verify your email before you can continue. Press the button below to receive a verification link at ${user.email}`}
        </Typography>

        <Stack paddingTop="32px" spacing={3}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={() => {
              setSending(true);

              const { protocol, host } = window.location;
              const continueUrl = `${protocol}//${host}${PATHS.accountMFA}`;
              firebase
                .auth()
                .currentUser?.sendEmailVerification({
                  url: continueUrl,
                })
                .then(() => {
                  setStep(MFAStep.SUCCESS);
                })
                .catch((e) => {
                  console.error(e);
                  setStep(MFAStep.ERROR);
                })
                .finally(() => {
                  setSending(false);
                });
            }}
            loading={sending}
          >
            Send verification link
          </LoadingButton>
        </Stack>
      </Grid>
    </Grid>
  );
}
