// material
import { styled } from "@mui/material/styles";
import { Box, List, BoxProps, ListSubheader } from "@mui/material";

import { PATHS } from "routes/paths";
import SvgIconStyle from "components/SvgIconStyle";
import NavItem from "components/NavItem";
// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary,
}));

// ----------------------------------------------------------------------

type NavItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  children?: {
    title: string;
    path: string;
  }[];
};

export const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

export const getTrainingProgramsImage = (
  name: string,
  width?: number,
  height?: number
) => (
  <Box
    component={"img"}
    width={width ?? "100%"}
    height={height ?? "100%"}
    src={`/static/trainingPrograms/${name}.png`}
    alt={"Not found"}
  />
);

type NavItemType = {
  title: string;
  path: string;
  icon: typeof ICONS.chat;
};

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  settings: getIcon("ic_settings"),
  elements: getIcon("ic_elements"),
};

interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined;
}

export default function NavSection({
  isShow = true,
  ...other
}: NavSectionProps) {
  let dashboardNavItems: Array<NavItemType> = [
    {
      title: "Usage Insights",
      path: PATHS.usageInsights,
      icon: ICONS.elements,
    },
    {
      title: "Well-being Insights",
      path: PATHS.wellbeingInsights,
      icon: ICONS.dashboard,
    },
    {
      title: "Care Experience",
      path: PATHS.careExperience,
      icon: ICONS.banking,
    },
    {
      title: "Resources",
      path: PATHS.resources,
      icon: ICONS.chat,
    },
  ];
  // show chat menu if usrRoles have chat roles

  const navConfig = [
    {
      subheader: "dashboard",
      items: dashboardNavItems,
    },
  ];

  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
            {items.map((item: NavItemProps) => (
              <NavItem key={item.title} item={item} isShow={isShow} />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
