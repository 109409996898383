import { db } from "contexts/FirebaseContext";
import * as CloudFunctions from "utils/firebase/cloudFunctions";

export function validateAdmin(uid: string) {
  return CloudFunctions.validateAdmin({ uid });
}

export function getGetTherapist(userId: string) {
  return db.collection("therapists").where("uid", "==", userId);
}
