import { Country } from "../contexts/CountryFilterContext";

export const filterTimezonesByCountry = (
  selectedCountry: string,
  timezoneData: Record<string, Country>
): Array<string> => {
  return Object.keys(timezoneData)?.filter(
    (timezone) => timezoneData[timezone]?.name === selectedCountry
  );
};
