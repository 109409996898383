import { useEffect, useRef, useState } from "react";
import firebase from "firebase/app";
import { FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";
import { PaperPage } from "components/PaperPage";
import { useMultiFactorLogin } from "hooks/useMultiFactorLogin";
import { PATHS } from "routes/paths";
import { isGoogleApiError } from "utils/isGoogleApiError";
import {
  TwoFactorVerificationForm,
  VerificationCodeFormValues,
} from "./TwoFactorVerificationForm";
import { MFAStep } from "../../../@types/authentication";

export function MFALogin({
  resolver,
}: {
  resolver: firebase.auth.MultiFactorResolver;
}) {
  const navigate = useNavigate();
  const { requestAuthenticationCode, resolveSignIn } =
    useMultiFactorLogin(resolver);
  const firstHint = resolver.hints[0]; // Currently we only support one hint

  const [step, setStep] = useState<MFAStep>(MFAStep.VERIFY);
  const reCaptchaVerifier = useRef<firebase.auth.RecaptchaVerifier | null>(
    null
  );

  const onSubmit = async (
    values: VerificationCodeFormValues,
    helpers: FormikHelpers<VerificationCodeFormValues>
  ) => {
    const { verificationCode } = values;
    try {
      await resolveSignIn(verificationCode);
      setStep(MFAStep.LOGGING_IN);
      navigate(PATHS.root);
    } catch (e) {
      if (isGoogleApiError(e)) {
        if (e.code === "auth/invalid-verification-code") {
          helpers.setFieldError("verificationCode", "invalid");
          return;
        }
      }

      console.error(e);
      setStep(MFAStep.ERROR);
    }
  };

  useEffect(() => {
    if (reCaptchaVerifier?.current !== null) {
      return;
    }

    reCaptchaVerifier.current = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container-id",
      {
        size: "invisible",
      }
    );
    reCaptchaVerifier.current.render().then(() => {
      if (reCaptchaVerifier.current) {
        // we are just making TS happy here
        requestAuthenticationCode(reCaptchaVerifier.current, firstHint);
      }
    });
  }, [firstHint, requestAuthenticationCode]);

  if (step === MFAStep.LOGGING_IN || step === MFAStep.ERROR) {
    let title = "Success";
    if (step === MFAStep.ERROR) {
      title = "An error occurred";
    }

    let message = "Logging you in";
    if (step === MFAStep.ERROR) {
      message =
        "An error occurred. Try again or contact support if the issue persists";
    }
    return (
      <>
        <PaperPage headTitle={"Complete Sign-in"} title={title}>
          <Typography textAlign="center">{message}</Typography>
        </PaperPage>
      </>
    );
  }

  const onResendRequested = () => {
    if (reCaptchaVerifier.current) {
      requestAuthenticationCode(reCaptchaVerifier.current, firstHint);
    } else {
      const error = new Error(
        "Authentication code was requested, but the reCaptcha is not initialized"
      );
      console.warn(error);
    }
  };

  return (
    <>
      <PaperPage headTitle={"Complete Sign-in"} title={"Verify it's you"}>
        <TwoFactorVerificationForm
          hint={`Please enter the code we sent to ${firstHint.displayName}`}
          onSubmit={onSubmit}
          onResendRequested={onResendRequested}
        />
      </PaperPage>
    </>
  );
}
