import { db, firebaseAuth } from "../../contexts/FirebaseContext";
// import firebase from 'firebase/app';
import { collections } from "../constants";

export function forceRefreshClaims(uid: string) {
  return db
    .collection(collections.C_CLAIM_AUTOSYNC_UTIL)
    .doc(`${uid}`)
    .onSnapshot((snapshot) => {
      firebaseAuth().currentUser?.getIdToken(true);
    });
}
