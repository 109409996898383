import { alpha, darken } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

interface Brand {
  blue: string;
  red: string;
  teal: string;
}

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    neutral: string;
    contrast: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
    brand: Brand;
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    blue: true;
    teal: true;
  }
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    blue: true;
    teal: true;
  }
}

declare module "@mui/material" {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const BRAND = {
  blue: "#1F1E5F",
  red: "#F35C5C",
  teal: "#71F2E3",
};

const PRIMARY = {
  lighter: "#f79d9d",
  light: "#f57c7c",
  main: "#F35C5C",
  dark: "#ee1d1d",
  darker: "#bb0d0d",
  yellow: "#ffd699",
};
const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A",
};
const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
};
const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
};
const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
};
const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  dark1: "#EE8282",
  darker: "#7A0C2E",
};

const GREEN = {
  light: "#65C467",
};

const GREY = {
  //TODO  - Figure out which of these greys I can delete
  0: "#FFFFFF",
  50: "#D8D7DD",
  100: "#F3F3F7",
  200: "#E8E8EF",
  300: "#DEDEE9",
  400: "#C4CDD5",
  450: "#919399",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E80C7", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CBEEF5"],
  green: ["#2CD9C5", "#71F2E3", "#A1D9A6", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFD89E", "#FFF3D6"],
  red: ["#C55365", "#F35C5C", "#FF8080", "#FFBD98", "#FFF2D4"],
};

const COMMON = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY, contrastText: "#fff" },
  secondary: { ...SECONDARY, contrastText: "#fff" },
  info: { ...INFO, contrastText: "#fff" },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: "#fff" },
  blue: {
    contrastText: "#fff",
    main: BRAND.blue,
    dark: alpha(BRAND.blue, 0.5),
  },
  teal: {
    main: BRAND.teal,
    contrastText: "#fff",
    dark: darken(BRAND.teal, 0.1),
  },
  brand: BRAND,
  grey: GREY,
  green: GREEN,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
      darkBlue: BRAND.blue,
      white: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#fff",
      neutral: GREY[300],
      contrast: BRAND.blue,
      therapistCardBg: "#F3F3F7",
    },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    text: {
      primary: "#fff",
      secondary: GREY[500],
      disabled: GREY[600],
      darkBlue: BRAND.blue,
      white: "#fff",
      contrast: GREY[300],
    },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
  metrics: {
    lightGreen: "#BDD773",
  },
};

export default palette;
