import { useEffect } from "react";
import * as Yup from "yup";
import { Form, FormikHelpers, FormikProvider, useFormik } from "formik";

import { LoadingButton } from "@mui/lab";
import { Grid, Stack, TextField, Typography } from "@mui/material";

export const VerificationCodeSchema = Yup.object().shape({
  verificationCode: Yup.string().required(
    "Please enter the code we sent to your phone"
  ),
});

export type VerificationCodeFormValues = {
  verificationCode: string;
};

type TwoFactorVerificationFormProps = {
  hint: string;
  onSubmit: (
    values: VerificationCodeFormValues,
    helpers: FormikHelpers<VerificationCodeFormValues>
  ) => Promise<void>;
  onResendRequested: () => void;
};

export function TwoFactorVerificationForm({
  onResendRequested,
  onSubmit,
  hint,
}: TwoFactorVerificationFormProps) {
  const formik = useFormik<VerificationCodeFormValues>({
    initialValues: {
      verificationCode: "",
    },
    validationSchema: VerificationCodeSchema,
    onSubmit,
  });
  const { isSubmitting, handleSubmit, getFieldProps, errors } = formik;

  /* we are setting the focus with a timeout because otherwise it's stolen
  by other components.
*/
  useEffect(() => {
    const timer = setTimeout(() => {
      const inputElement = document.getElementById("mfa-verification-code");
      if (inputElement) {
        inputElement?.focus?.();
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid item lg={12}>
            <Typography textAlign="center">{hint}</Typography>
            <Stack paddingTop="32px" spacing={3}>
              <TextField
                fullWidth
                id={"mfa-verification-code"}
                {...getFieldProps("verificationCode")}
                type="text"
                label={"Verification code"}
                error={Boolean(errors.verificationCode)}
                helperText={errors.verificationCode}
              />

              <div id="recaptcha-container-id"></div>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Verify
              </LoadingButton>

              <LoadingButton
                fullWidth
                size="large"
                type="button"
                variant="contained"
                onClick={onResendRequested}
              >
                Send code again
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
