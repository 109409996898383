import { useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  CardActionArea,
  Drawer,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

// hooks
import useAuth from "../../hooks/useAuth";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";

// components
import LogoText from "../../components/LogoText";
import LogoWithSpeechBubble from "components/_dashboard/LogoWithSpeechBubble";
import MyAvatar from "../../components/MyAvatar";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
import { userApi } from "redux/userApi";
import { PATHS } from "../../routes/paths";
//

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

const UserInfo = styled("div")(({ theme }) => ({
  padding: theme.spacing(2, 2.5),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const LogoContainer = styled("div")(() => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  "@media screen and (max-height: 750px)": {
    display: "none",
  },
}));

const StyledScrollBar = styled(Scrollbar)(({ theme }) => ({
  background: theme.palette.background.neutral,
}));

const LogoutButton = styled(Typography)(() => ({
  padding: 0,
  margin: 0,
  fontSize: 12,
  textDecoration: "underline",
  color: "black",
  justifyContent: "flex-start",
  cursor: "pointer",
}));

const MFAButton = styled(Typography)(() => ({
  margin: 0,
  paddingTop: 2,
  paddingBottom: 4,
  fontSize: 12,
  textDecoration: "underline",
  color: "black",
  justifyContent: "flex-start",
  cursor: "pointer",
}));

// ----------------------------------------------------------------------

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: "flex",
          cursor: "pointer",
          borderRadius: "50%",
          alignItems: "center",
          color: "text.primary",
          justifyContent: "center",
          border: "solid 1px currentColor",
          ...(collapseClick && {
            borderWidth: 2,
          }),
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            bgcolor: "currentColor",
            transition: (theme) => theme.transitions.create("all"),
            ...(collapseClick && {
              width: 0,
              height: 0,
            }),
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { user, logout } = useAuth();
  const { data: userState } = userApi.useLoadUserQuery(user?.uid);

  const has2FAEnrolled = user?.multiFactor?.enrolledFactors.length > 0;

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <StyledScrollBar
      sx={{
        displayPrint: "none",
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          position: "relative",
          zIndex: 2,
          ...(isCollapse && {
            alignItems: "center",
          }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
            <LogoText />
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && (
              <IconCollapse
                onToggleCollapse={onToggleCollapse}
                collapseClick={collapseClick}
              />
            )}
          </MHidden>
        </Stack>

        {isCollapse ? (
          <MyAvatar sx={{ mx: "auto", mb: 2 }} />
        ) : (
          <AccountStyle>
            <UserInfo>
              <MyAvatar />
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                  {userState?.fullname || user?.displayName || ""}
                </Typography>

                {!has2FAEnrolled && (
                  <MFAButton onClick={() => navigate(PATHS.accountMFA)}>
                    Enable 2FA
                  </MFAButton>
                )}

                <LogoutButton onClick={logout}>Log out</LogoutButton>
              </Box>
            </UserInfo>
          </AccountStyle>
        )}
      </Stack>

      <NavSection
        sx={{ position: "relative", zIndex: 2 }}
        isShow={!isCollapse}
      />

      {!isCollapse && (
        <Stack
          spacing={3}
          alignItems="center"
          sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: "center" }}
        >
          <LogoContainer>
            <LogoWithSpeechBubble
              text={userState?.fullname || user?.displayName || "Welcome back!"}
            />
          </LogoContainer>

          <div>
            <Typography gutterBottom variant="subtitle1" />
          </div>
        </Stack>
      )}
    </StyledScrollBar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
      className="no-print"
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: "blur(6px)",
                WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) =>
                  alpha(theme.palette.background.default, 0.88),
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
