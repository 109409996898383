// material
import { styled } from "@mui/material/styles";
import { Card, Container, Box } from "@mui/material";
// components
import Page from "components/Page";
import { MHidden } from "components/@material-extend";
import LoadingScreen from "components/LoadingScreen";
import useAuth from "hooks/useAuth";
import { ReactNode, useEffect } from "react";
import { useSnackbar } from "notistack";
import { MIconButton } from "components/@material-extend";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import LogoText from "components/LogoText";
import LogoWithSpeechBubble from "components/_dashboard/LogoWithSpeechBubble";
import TermsFooter from "components/TermsFooter";

// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    backgroundColor: theme.palette.grey["100"],
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 380,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  overflow: "visible",
  margin: theme.spacing(2, 0, 2, 2),
  background: theme.palette.background.neutral,
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 600,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
}));

const LoadingIconContainer = styled("div")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  background: "rgba(255,255,255,0.4)",
  zIndex: 2,
}));

const LogoContainer = styled("div")(() => ({
  padding: "42px 42px 0 42px",
  height: "100px",
}));

const ContentContainer = styled(Container)(() => ({
  position: "relative",
}));

// ----------------------------------------------------------------------

export default function AuthLayout({
  children,
  title,
  headTitle,
}: {
  children: ReactNode;
  title: string;
  headTitle: string;
}) {
  const { attemptingLogin, showLoginSuccessMessage } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (showLoginSuccessMessage) {
      enqueueSnackbar("Login succesful!", {
        variant: "success",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    }
  }, [showLoginSuccessMessage, enqueueSnackbar, closeSnackbar]);

  return (
    <RootStyle headTitle={headTitle}>
      {attemptingLogin && (
        <LoadingIconContainer>
          <LoadingScreen sx={{ backgroundColor: "transparent" }} />
        </LoadingIconContainer>
      )}

      <MHidden width="mdDown">
        <SectionStyle>
          <LogoContainer>
            <LogoText />
          </LogoContainer>

          <LogoWithSpeechBubble text={title} />
        </SectionStyle>
      </MHidden>

      <ContentContainer>
        <ContentStyle>{children}</ContentStyle>

        <Box
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          display={{ xs: "none", lg: "block" }}
        >
          <Container>
            <TermsFooter showLink={false} />
          </Container>
        </Box>
      </ContentContainer>
    </RootStyle>
  );
}
