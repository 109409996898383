import { createSlice } from "@reduxjs/toolkit";

type UserState = {
  isLoading: boolean;
  error: boolean;
};

const initialState: UserState = {
  isLoading: true,
  error: false,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;
