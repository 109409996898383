const EllipseCounsellorsSearch = () => {
  return (
    <svg
      width="611"
      height="611"
      viewBox="0 0 611 611"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: "relative", top: "6px" }}
    >
      <path
        opacity="0.05"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M611 233.757C402.786 233.757 233.995 402.548 233.995 610.762L0.238255 610.762C0.238269 273.447 273.686 -1.47445e-05 611 0L611 233.757Z"
        fill="#1F1E5F"
      />
    </svg>
  );
};

export default EllipseCounsellorsSearch;
