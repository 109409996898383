import { useContext, useEffect, useState } from "react";
import { getPermissionBasedInvitationCode } from "../services/InvitationCodeService";
import useAuth from "./useAuth";
import { QueryContext } from "../contexts/QueryContext";

const useGetPermissionBasedInvitationCodes = () => {
  const [permissionBaseInvitationCodes, setPermissionBaseInvitationCodes] =
    useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { user } = useAuth();
  const { setInvitationCode, setIsEnabledDemographicQuestionFilters } =
    useContext(QueryContext);

  useEffect(() => {
    async function getPermissionBaseCodes() {
      const {
        invitationCodes: permissionBaseInvitationCodeString,
        isEnabledDemographicQuestionFilters,
      } = await getPermissionBasedInvitationCode({ uid: user?.uid });
      setIsEnabledDemographicQuestionFilters(
        isEnabledDemographicQuestionFilters
      );
      setPermissionBaseInvitationCodes(permissionBaseInvitationCodeString);
      setInvitationCode(permissionBaseInvitationCodeString?.join(", "));
      setLoading(false);
    }

    getPermissionBaseCodes();
  }, [
    setInvitationCode,
    setIsEnabledDemographicQuestionFilters,
    setPermissionBaseInvitationCodes,
    user?.uid,
  ]);

  return { permissionBaseInvitationCodes, loading };
};

export default useGetPermissionBasedInvitationCodes;
