/*
 * This function extracts a list of values from a comma-separated string.
 */
// eslint-disable-next-line require-jsdoc
export function extractValuesFromCommaSeparatedString(
  inputString: string
): string[] {
  return (
    inputString
      ?.split(",")
      ?.map((s) => s.trim())
      ?.filter(Boolean) ?? []
  );
}
